@import "mixins";

.header {
  @include desktop {
    @include flex(flex-start);
    gap: 40px;
  }

  @include mobile {
    display: grid;
    gap: 20px;
  }
}

.link {
  font-weight: var(--normal);
}

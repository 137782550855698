.text {
  font-weight: var(--bold);
  padding: 4px;
  text-align: center;
}

.warning {
  background: var(--warning);
  color: white;
  text-transform: uppercase;
}

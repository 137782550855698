.card {
  gap: 20px;
}

.header {
  border-bottom: 1px solid var(--card-border);
  font-size: 16px;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.footer {
  margin-top: 28px;
}

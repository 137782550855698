@import "mixins";

.overlay {
  @include flex;

  padding: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.title {
  font-size: 24px;
}

@import "mixins";

.charts {
  display: grid;
  gap: var(--grid-gap);

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

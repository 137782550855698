@import "mixins";

.wallet {
  @include flex(space-between);

  background: var(--button-primary-bg);
  border-radius: 16px;
  color: var(--button-primary-text);
  padding: 16px 20px;
  text-align: left;
}

.address {
  font-size: var(--font-size-small);
  // font-weight: var(--normal);
}

@import "mixins";

// toggle
.toggle {
  font-size: 18px;
  font-weight: var(--bold);
  gap: 12px;
  line-height: 1;
  padding: 20px;
}

.search {
  background-color: var(--bg-muted);

  input {
    flex: 1;
    background: transparent;
    padding: 16px 20px;
  }

  ::placeholder {
    color: var(--text-muted);
  }

  svg {
    margin: 16px 0;
    margin-left: 20px;
  }
}

// list of all groups
.list {
  max-height: 320px;
  overflow-y: auto;
  padding: 20px;
}

.empty {
  @include flex;
  font-size: var(--font-size-small);
  font-weight: var(--bold);
}

/* group */
.title {
  font-size: var(--font-size-small);
  font-weight: var(--bold);
  padding-bottom: 8px;
}

/* item */
.item:hover {
  background: var(--bg);
}

.muted {
  opacity: 0.5;
}

@import "mixins";

/* token */
.header {
  flex: 1;
  text-align: left;
  overflow: hidden;
}

.title,
.name,
.description {
  @include truncate;
}

.title {
  font-weight: var(--bold);
}

.name {
  color: var(--text-muted);
  font-size: 11px;
  font-weight: var(--normal);
}

.description {
  font-size: 11px;
}

.extra {
  font-size: var(--font-size-small);
  font-weight: var(--normal);
}
